import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { sortBy, get, fromPairs, last, isEmpty, mapValues } from 'lodash';
import { format as formatDate } from 'date-fns';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { fields, expressionTokens, } from '../../shared/models/customAccountItem';
import { documentTypes, dimensions, manualTypes, } from '../../shared/config';
import NumberInput from '../NumberInput';

const { entries } = Object;

export default function CustomAccountItemFormModal(props) {
  const { company, customSections = [], enabledBudgetSubjectTypes, customAccountItems = [], isOpen, values, onClickClose } = props;
  const isNew = !values;
  const statedFields = useFormState(values, fields({ company, customAccountItems, enabledBudgetSubjectTypes, }), isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const isManual = !!statedFields.isManual.value;
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    const fieldValues = mapValues(statedFields, 'value');
    props.onSubmit({
      ...fieldValues,
    });
  };
  const expressionSuffixContent = () => {
    const { invalidTokens } = expressionTokens(statedFields.expression.value);
    if(isEmpty(invalidTokens)) return null;

    return (
      <div className="text-danger">
        正しくない形式の記述があります。
        ({invalidTokens.join(', ')})
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 1000 }}>
      <ModalHeader>
        カスタム科目{isNew ? '追加' : '編集'}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <ModalBody>
            {
              entries(statedFields).map(([fieldName, fieldSetting]) => {
                return (
                  <Field
                    key={fieldName}
                    name={fieldName}
                    {...fieldSetting}
                    {...(fieldName === 'expression' && { suffixContent: expressionSuffixContent})}
                  />
                )
              })
            }
          </ModalBody>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
