import React from 'react';
import { pick } from 'lodash';
import { Link } from 'react-router-dom';

import AdminPage from '../hocs/AdminPage';
import firebase from '../../firebase';
import { adminFields as fields, plans, features, } from '../../shared/models/company';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';

const { entries } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default AdminPage(function AdminCompanies(props) {
  const companies = useCollectionSubscription(companiesRef);

  return (
    <div className="company-members">
      <div className="container py-5">
        <div className="d-flex justify-content-center mb-2">
          <h3>会社管理</h3>
        </div>
        <table className="table table-hover mt-5 table-bordered">
          <thead class="thead-light">
            <tr>
              <th>会社名</th>
              <th>試用期間(日)</th>
              <th>ライセンス数</th>
              <th>トライアル</th>
              <th>プラン</th>
              <th>オプション</th>
              <th>IPアドレス制限</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              companies.map(({ id, ref, display_name: name, trialDays = 30, licenseCount = 10, isTrial, enabledPlans, enabledFeatures = [], ipAddressRestriction = false, whiteIps, shouldDelete = false, }) => {
                return (
                  <tr key={id} id={id}>
                    <td>
                      <Link to={`/companies/${id}`}>
                        {name}
                      </Link>
                      {shouldDelete && <div className="ml-1 badge badge-danger">削除予定</div>}
                    </td>
                    <td className="text-right">
                      {trialDays}
                    </td>
                    <td className="text-right">
                      {licenseCount}
                    </td>
                    <td className="text-right">
                      {isTrial && <span className="badge badge-info">トライアル</span>}
                    </td>
                    <td className="text-right">
                      {entries(pick(plans, enabledPlans)).map(([k, v]) => <div key={k}>{v.label}</div>)}
                    </td>
                    <td className="text-right">
                      {enabledFeatures?.map(_ => features[_]?.label).join(', ')}
                    </td>
                    <td>
                      {
                        ipAddressRestriction && (
                          <div>
                            <div className="badge badge-info">
                              制限ON
                            </div>
                            <div className="small" style={{ whiteSpace: 'pre-line' }}>
                              {whiteIps}
                            </div>
                          </div>
                        )
                      }
                    </td>
                    <td>
                      <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '会社 編集', fields: fields() }} />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
