const { omit, keyBy, isEmpty, sumBy, } = require('lodash');

const { accountItemCategoriesByName } = require('../category');

const { entries } = Object;

const fields = ({ dimensions, accountItems, }) => {
  const _dimensions = omit(dimensions, 'none');
  return {
    name: {
      type: 'string',
      label: '名称',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    dimension1: {
      type: 'select',
      label: '明細項目1',
      options: entries(_dimensions).map(([k, v]) => ({ label: v, value: k })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    dimension2: {
      type: 'select',
      label: '明細項目2',
      options: entries(_dimensions).map(([k, v]) => ({ label: v, value: k })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    accountItemIds: {
      type: 'multiSelect',
      label: '勘定科目',
      options: accountItems.filter(_ => ['pl', 'cr'].includes(accountItemCategoriesByName[_.account_category]?.type)).map(_ => ({ label: _.name, value: _.id })),
      validations: {
        maxLength: v => (v?.length ?? 0) <= 5,
        required: v => !isEmpty(v),
      },
      hint: '5つまで指定できます',
    },
  };
};

module.exports = {
  fields,
};
