import React from 'react';
import { Pagination as BootstrapPagination, PaginationItem, PaginationLink } from 'reactstrap';
import classnames from 'classnames';

export default function Pagination(props) {
  const { currentStart, currentEnd, totalCount, hasPrev, hasNext, prev, next, disabled = false, ...extraProps } = props;
  return (
    <div className={classnames('d-flex align-items-center gap-2', extraProps.className)} {...extraProps}>
      <div className="small text-muted">
        {currentStart} - {currentEnd}{Number.isFinite(totalCount) ? ` / ${totalCount}` : ''}
      </div>
      <BootstrapPagination listClassName="m-0">
        <PaginationItem disabled={!hasPrev || disabled}>
          <PaginationLink onClick={prev}>
            <span className="fas fa-angle-left" />
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={!hasNext || disabled}>
          <PaginationLink onClick={next}>
            <span className="fas fa-angle-right" />
          </PaginationLink>
        </PaginationItem>
      </BootstrapPagination>
    </div>
  );
};
