import React, { Component, Fragment, useState, } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { sortBy, omit, pickBy } from 'lodash';
import { useToggle, useAsync, } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';

import firebase, { functions } from '../../firebase';
import HeaderNav from '../HeaderNav';
import HelpLink from '../HelpLink';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import FreeeCompaniesModal from '../modals/FreeeCompaniesModal';
import OverlayLoading from '../OverlayLoading';
import UserDisplay from '../UserDisplay';
import env from '../../env';

const addCompany = functions.httpsCallable('addCompany');
const db = firebase.firestore();
const auth = firebase.auth();
const getCompanyMembers = functions.httpsCallable('getCompanyMembers');
const authFreee = functions.httpsCallable('authFreee');
const usersRef = db.collection('users');
const companiesRef = db.collection('companies');
const { keys } = Object;

export default function Dashboard (props) {
  const { user } = props;
  const [isDragging, toggleDragging] = useToggle(false);
  const [isFetchingFreeeCompanies, toggleFetchingFreeeCompanies] = useToggle(false);
  const [shouldShowFreeeCompaniesModal, toggleFreeeCompaniesModal] = useToggle(false);
  const [freeeCompanies, setFreeeCompanies] = useState([]);
  const _companies = useDocumentsFetch(keys(pickBy(user.companies || {}, 'isJoined')).map(_ => companiesRef.doc(_)), [user]);
  const companies = _companies.filter(_ => _);
  const sortedItems = sortBy(sortBy(companies, _ => _.display_name), _ => user.companies[_.id].index || 0);
  const integrateWithFreee = async () => {
    const { data: url } = await authFreee();
    window.location.href = url;
  };
  const separateFromFreee = async () => {
    if (!window.confirm('本当に連携を解除しますか？')) return;

    await usersRef.doc(user.uid).update({ freee: firebase.firestore.FieldValue.delete() })
    toast.success('freeeとの連携を解除しました');
  };
  const fetchFreeeCompanies = async () => {
    const token = await auth.currentUser.getIdToken(true);
    const url = `${env('CLOUD_FUNCTIONS_ENDPOINT')}/fetchFreeeCompanies`;
    const headers = { 'Firebase-User-Token': token };
    toggleFetchingFreeeCompanies(true);
    const res = await fetch(url, { headers });
    const freeeCompanies = await res.json();
    setFreeeCompanies(freeeCompanies);
    toggleFetchingFreeeCompanies(false);
    toggleFreeeCompaniesModal(true);
  };
  const onSubmitFreeeCompaniesModal = async (freeeCompany) => {
    if (companies.some(_ => _.id === freeeCompany.id)) {
      toast.error('すでに追加されています');
      toggleFreeeCompaniesModal(true);
      return;
    }

    await addCompany({
      values: {
        sourceId: freeeCompany.id,
        ...omit(freeeCompany, ['id']),
        uid: user.uid,
      },
    });

    toast.success('事業所を追加しました');
    toggleFreeeCompaniesModal(false);
  }
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMove(sortedItems, removedIndex, addedIndex).map(_ => _.id);
    await user.ref.set({
      companies: keys(user.companies).reduce((x, y) => ({
        ...x,
        [y]: { index: newIds.indexOf(y) },
      }), {}),
    }, { merge: true });
  };

  return (
    <div className="dashboard">
      <HeaderNav {...props} />
      <div className="container py-5">
        <div className="d-flex flex-column justify-content-center align-items-stretch">
          <div className="d-flex justify-content-end mb-3">
            <HelpLink text="freeeと連携する" />
          </div>
          <div className="d-flex justify-content-center">
            <h3>事業所一覧</h3>
          </div>
          <div className="d-flex justify-content-end py-3">
            {
              !user.freee && (
                <Button color="primary" onClick={integrateWithFreee}>
                  <span className="fas fa-link mr-1" />
                  freeeと連携する
                </Button>
              )
            }
            {
              user.freee && (
                <Fragment>
                  <Button color="primary" onClick={fetchFreeeCompanies} disabled={isFetchingFreeeCompanies}>
                    {
                      isFetchingFreeeCompanies
                        ? <span className="fas fa-spin fa-spinner mr-1" />
                        : <span className="fas fa-plus mr-1" />
                    }
                    freeeから事業所を追加する
                  </Button>
                  <Button className="ml-2" color="danger" onClick={separateFromFreee} disabled={isFetchingFreeeCompanies}>
                    <span className="fas fa-unlink mr-1" />freeeとの連携を解除する
                  </Button>
                </Fragment>
              )
            }
          </div>
          <div className="mt-3 row">
            <div className="col-md-10 offset-md-1">
              {
                sortedItems.length > 0 ? (
                  <table className="table table-hover table-bordered">
                    <thead className="thead-light text-center">
                      <tr>
                        <th>事業所名</th>
                        <th>メンバー</th>
                        <th></th>
                      </tr>
                    </thead>
                    <Container
                      dragHandleSelector=".drag-handle"
                      onDrop={onDrop}
                      onDragStart={_ => toggleDragging(true)}
                      onDragEnd={_ => toggleDragging(false)}
                      dropPlaceholder={{ style: { background: 'eee', } }}
                      render={(ref) => (
                        <tbody ref={ref}>
                          {
                            sortedItems.filter(_ => _.display_name).map((company) => {
                              const { display_name: name, id } = company;
                              return (
                                <Draggable
                                  key={id}
                                  render={() => (
                                    <tr style={{ display: !isDragging && 'table-row' }}>
                                      <td>
                                        <div className="d-flex gap-2 align-items-center">
                                          <div className="drag-handle text-muted cursor-pointer">
                                            <span className="fas fa-grip-vertical" />
                                          </div>
                                          <Link to={`/companies/${id}`}>{name}</Link>
                                        </div>
                                      </td>
                                      <MembersCell company={company} />
                                      <td>
                                        <Button tag={Link} to={`/companies/${id}/members`}>
                                          <span className="fas fa-cog mr-1" />
                                          ユーザー設定
                                        </Button>
                                      </td>
                                    </tr>
                                  )}
                                />
                              );
                            })
                          }
                        </tbody>
                      )}
                    >
                    </Container>
                  </table>
                ) : (
                  <div>事業所はまだありません。</div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <FreeeCompaniesModal isOpen={shouldShowFreeeCompaniesModal} freeeCompanies={freeeCompanies} onClickClose={toggleFreeeCompaniesModal.bind(null, false)} onSubmit={onSubmitFreeeCompaniesModal} />
    </div>
  );
}

function MembersCell (props) {
  const { company } = props;
  const { value: members = [], loading, } = useAsync(async () => {
    const { data: members } = await getCompanyMembers({ companyId: company.id });
    return members;
  });

  return (
    <td className="position-relative">
      {
        members.map((member, i) => {
          return (
            <div key={i}>
              <UserDisplay user={member} />
            </div>
          );
        })
      }
      <OverlayLoading isOpen={loading} />
    </td>
  );
}
