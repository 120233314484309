const { invert, mapKeys } = require('lodash');

const jas = {
  itemName: '科目名',
  subItemName: '明細名',
  rowType: '数値項目',
  opening: '期首残高',
  sum: '発生額合計',
  total: '合計',
  trial: '試算表残高',
  itemName: '科目名',
  subItemName: '明細名',
  diff: '増減額',
  diffRate: '増減率',
  itemName: '科目名',
  subItemName: '明細名',
  rowType: '数値項目',
  sum: '合計',
  uniqSum: '使用しているfreee項目の合計',
  trial: '試算表残高',
  itemName: '科目名',
  subItemName: '明細名',
  rowType: '数値項目',
  sum: '合計',
  aggregationType: '集計種別',
  yearMonth: '年月',
  itemName: '科目名',
  subItemName: '明細名',
  budgetAmount: '予算',
  amount: '当期実績',
  budgetDiff: '差額',
  achievementRate: '達成率',
  estimatedAmount: '着地見込',
  prevYearAmount: '前期実績',
  prevYearDiff: '増減額',
  prevYearDiffRate: '増減率',
  note: 'メモ',
  customPartnerName: 'カスタム取引先',
  accountItemName: '勘定科目',
  openingBalance: '前月残高',
  closingBalance: '当月残高',
  creditLimit: '与信限度額',
  creditBalance: '与信残高',
  creditRate: '与信残高割合',
  journalNumber: '仕訳番号',
  date: '日付',
  accountName: '科目',
  route: '登録した方法',
  section: '部門',
  partner: '取引先',
  item: '品目',
  segment1: 'セグメント1',
  segment2: 'セグメント2',
  segment3: 'セグメント3',
  debitAmount: '借方金額',
  creditAmount: '貸方金額',
  abstract: '摘要',
  metric: '数値項目',
  metricType: '指標種別',
  metricLabel: '指標',
  valueType: '数値種別',
  threshold: '基準値',
  journalsCheckDimensions: 'チェックタグ',
  partnersJournalCheckTargets: '取引先チェック対象',
  itemsJournalCheckTargets: '品目チェック対象',
  sectionsJournalCheckTargets: '部門チェック対象',
  segment1sJournalCheckTargets: 'セグメント1チェック対象',
  segment2sJournalCheckTargets: 'セグメント2チェック対象',
  segment3sJournalCheckTargets: 'セグメント3チェック対象',
  defaultDebitTaxes: '借方デフォルト税区分',
  defaultCreditTaxes: '貸方デフォルト税区分',
  id: 'id',
  amount: '金額',
  date: '日付',
  direction: '貸借',
  partners: '取引先',
  sections: '部門',
  items: '品目',
  memo: 'メモ',
  abstract: '摘要',
  url: 'url',
  isStarred: 'isStarred',
  journalNumber: '仕訳番号',
  date: '取引日',
  description: '検出事項',
  status: '状態',
  ignoringNote: '無視メモ',
  freeeUrl: 'freeeUrl',
  targetMonth: '年月',
  subjectName: '主体',
  description: '検出事項',
  status: '状態',
  ignoringNote: '無視メモ',
  ruleType: '種別',
  name: '名称',
  message: 'メッセージ',
  journalRuleType: 'ルール種別',
  displays: '表示画面',
  dimension: '明細',
  isManual: '手入力',
  expression: '計算式',
  displayExpression: '表示形式',
  amount: '当期実績',
  comparisonAmount: '比較金額',
  accountItem: '勘定科目',
  customAccountItem: 'カスタム科目',
  category: 'カテゴリ',
  occurrence: '発生',
  closing: '累計',
  debit: '借方',
  credit: '貸方',
  types: '区分',
  accountCategoryName: 'カテゴリ',
  hierarchyLevel: '階層',
  totalAmount: '合計',
  tax: '税区分',
  comparedBudgetAmount: '比較予算',
　diffBetweenBudgets: '予算差額',
  budgetSubjectType: '区分種別',
  budgetSubjectName: '区分名',
  dimensionType: '明細種別',
  closingDate: '日付',
};

const invertedJas = invert(jas);

module.exports = {
  jas,
  validations: {
    general: {
      required: '必須項目です',
      greaterThanOrEqualTo0: '0以上で指定してください。',
      unique: '他に存在します',
      exists: '存在しない値です',
      format: '形式が正しくありません',
      validTokens: '正しくない形式の記述があります',
      maxLength: '上限を超えています',
    },
  },
  mapKeysToJa: (_, _jas = {}) => mapKeys(_, (v, k) => {
    const [, main, sub] = k.match(/^([^_]*)(.*)/) || [];
    return (({ ...jas, ..._jas })[main] || main) + sub;
  }),
  mapKeysFromJa: (_, _jas = {}) => mapKeys(_, (v, k) => {
    const [, main, sub] = k.match(/^([^_]*)(.*)/) || [];
    return (({ ...invertedJas, ..._jas })[main] || main) + sub;
  }),
};
