const { pick, last, fromPairs, sortBy, get, flatten, isEmpty } = require('lodash');
const { isURL, } = require('validator');
const { format: formatDate, } = require('date-fns');
const numeral = require('numeral');

const { plans, } = require('./company');
const { documentTypes, dimensions, manualTypes, } = require('../config');

const { keys, entries } = Object;
const displays = {
  periodComparisonBs: { label: '期間比較 BS', },
  periodComparisonPl: { label: '期間比較 PL', },
  periodComparisonCr: { label: '期間比較 CR', },
  monthlyTrendsBs: { label: '月次推移 BS', },
  monthlyTrendsPl: { label: '月次推移 PL', },
  monthlyTrendsCr: { label: '月次推移 CR', },

  // NOTE: 一旦、ここは以前のを利用する
  sectionsBudgetsPl: { label: '予実', },
  sectionBudgetsPl: { label: '予実月次推移', },
};
const expressionTokens = (expression) => {
  const tokens = expression?.match(/(((acc|cat|cus):"([^"]+)"(?:\:(累計値|発生値))?)|[+-/*()]|\d+)/g);
  const invalidTokens = (tokens || []).reduce((x, y) => x.replace(y, ''), expression || '').split(/\s+/).filter(_ => _);
  return { expression, tokens, invalidTokens };
};

const fields = ({ company, enabledBudgetSubjectTypes = {}, customAccountItems = [] } = {}) => {
  return {
    name: {
      type: 'string',
      label: '科目名',
      validations: {
        required: v => !isEmpty(v),
        unique: (v, s) => !s.conflicted && !customAccountItems.map(_ => _.name).includes(v),
      },
    },
    displays: {
      type: 'multiSelect',
      label: '表示画面',
      options: entries(pick(displays, company?.enabledPlans.flatMap(_ => plans[_].customAccountItem.displays))).map(([k, v]) => ({ label: v.label, value: k })),
    },
    dimension: {
      type: 'select',
      label: '明細',
      options: entries(dimensions).map(([k, v]) => ({ label: v, value: k })),
      validations: {
        required: v => !isEmpty(v),
        unmatched: v => isEmpty(v) || keys(dimensions).includes(v),
      },
      initialValue: 'none',
    },
    isManual: {
      type: 'boolean',
      label: '手入力',
      initialValue: false,
    },
    expression: {
      type: 'text',
      label: '計算式',
      validations: {
        required: v => !isEmpty(v),
        validTokens: v => expressionTokens(v).invalidTokens.length === 0,
      },
      hidden: _ => _.isManual,
    },
    manualType: {
      type: 'select',
      label: '手入力数値タイプ',
      options: entries(manualTypes).map(([k, v]) => ({ label: v, value: k })),
      validations: {
        required: v => !isEmpty(v),
        unmatched: v => isEmpty(v) || keys(manualTypes).includes(v),
      },
      initialValue: 'occurrence',
      hidden: _ => !_.isManual,
    },
    displayExpression: {
      type: 'string',
      label: '表示形式',
      initialValue: '0,0',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    ...entries(enabledBudgetSubjectTypes).reduce((x, [k, { label }]) => {
      return {
        ...x,
        [`${k}__budget__url`]: {
          type: 'string',
          label: `${label} 予算 スプレッドシート公開CSV URL`,
          validations: {
            format: v => isEmpty(v) || isURL(v || ''),
          },
          hidden: _ => !_.isManual,
        },
        [`${k}__performance__url`]: {
          type: 'string',
          label: `${label} 実績 スプレッドシート公開CSV URL`,
          validations: {
            format: v => isEmpty(v) || isURL(v || ''),
          },
          hidden: _ => !_.isManual,
        },
      };
    }, {}),
  };
};

const computeRelatedItems = (customAccountItem, customAccountItemsByName) => {
  const { isManual, expression } = customAccountItem || {};
  if(isManual || !expression) return [];

  return [
    ...(expression.match(/acc:"([^"]+)"/g) || [])
      .map(_ => _.match(/acc:"([^"]+)"/)[1])
      .map(_ => _.split(':')[0])
      .map(_ => ({ type: 'accountItem', value: _ })),
    ...(expression.match(/cat:"([^"]+)"/g) || [])
      .map(_ => _.match(/cat:"([^"]+)"/)[1])
      .map(_ => ({ type: 'accountCategory', value: _ })),
    ...flatten((expression.match(/cus:"([^"]+)"/g) || [])
      .map(_ => _.match(/cus:"([^"]+)"/)[1])
      .map(_ => computeRelatedItems(customAccountItemsByName[_], customAccountItemsByName)))
  ];
};

const processManualCsvRows = async (customAccountItem, budgetSubjects, months, budgetPerformanceType, rows) => {
  const suffix = ({ performance: '', budget: '_budget'})[budgetPerformanceType];
  const [firstMonth] = months;
  const [lastMonth] = months.slice(-1);
  const addedMonthValues = months.reduce((x, month) => {
    const monthString = formatDate(month, 'YYYY/MM');
    const csvMonthString = formatDate(month, 'YYYYMM');
    const row = rows.find(_ => _['年月'] === csvMonthString);
    return {
      ...x,
      ...[...(budgetPerformanceType === 'performance' ? [{ id: '', name: '全社' }] : []), ...budgetSubjects].reduce((x, budgetSubject) => {
        return {
          ...x,
          [[monthString, budgetSubject.id, suffix].join('')]: numeral(get(row, [budgetSubject.name], 0), 10).value() || 0, // NOTE: ""だとgetで初期値使われない
        };
      }, {}),
    };
  }, {});
  const monthValues = {
    ...customAccountItem.values,
    ...addedMonthValues,
  };
  const monthValuesPairs = sortBy(entries(monthValues), '0');

  // NOTE: ここ、CustomAccountItemFormModalと同一のロジック
  const balances = fromPairs(
    monthValuesPairs.reduce((x, [k, v], i) => {
      const monthString = k.slice(0, 7);
      const budgetSubjectId = k.slice(7);
      const sameBudgetSubjectIdItems = x.filter(_ => _[0].slice(7) === budgetSubjectId);
      return [
        ...x,
        [k, (
          // NOTE: 今回の対象外は、もともとの値を使う
          (monthString < formatDate(firstMonth, 'YYYY/MM') || formatDate(lastMonth, 'YYYY/MM') < monthString) ? (
            get(customAccountItem, `balances.${k}`) || 0
          ) : (
            v + ((formatDate(firstMonth, 'YYYY/MM') < monthString && monthString <= formatDate(lastMonth, 'YYYY/MM')) ? ((last(sameBudgetSubjectIdItems) || {})[1] || 0) : 0)
          )
        )],
      ];
    }, [])
  );

  const occurrences = monthValues;
  await customAccountItem.ref.update({
    values: monthValues,
    balances,
    occurrences,
  });
  return rows;
};

module.exports = {
  fields,
  computeRelatedItems,
  displays,
  expressionTokens,
  processManualCsvRows,
};
