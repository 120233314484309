import React, { Component, Fragment, useState, useEffect, useMemo, useRef, } from 'react';
import { Button, Nav, NavItem, NavLink,  } from 'reactstrap';
import { fromPairs, isNumber as _isNumber, mapValues, sum, orderBy, pick, uniq, range, isEmpty, sumBy, groupBy, get, keyBy, last, sortBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate, endOfMonth, addMonths, addYears } from 'date-fns';
import { useList, useToggle, useDebounce, useMap, } from 'react-use';
import { useLocation, useHistory } from 'react-router-dom';
import numeral from 'numeral';
import qs from 'qs';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ResizableBox } from 'react-resizable';

import firebase from '../../firebase';
import { dimensions, } from '../../shared/config';
import { dimensionsOfCompany, } from '../../shared/models/company';
import { canWriteNote, } from '../../abilities';
import { getAllCollectionDataByChunk } from '../../shared/firebase';
import { accountItemCategoriesByName } from '../../shared/category';
import { mapKeysToJa, } from '../../shared/texts';
import { fields, } from '../../shared/models/twoDimensionsSetting';
import { log, fiscalYearOfPeriod, } from '../../utils';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import usePagination from '../hooks/usePagination';
import useMainTableUtilities from '../hooks/useMainTableUtilities';
import TrialQueriesSection from '../TrialQueriesSection';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import ModalButton from '../ModalButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import CompanyPage from '../hocs/CompanyPage';
import CommentsDropdown from '../CommentsDropdown';
import QuerySelector from '../QuerySelector';
import ExportButton from '../ExportButton';
import ProgressButton from '../ProgressButton';
import Pagination from '../Pagination';
import HoveredCommenter from '../HoveredCommenter';
import HoveredNoter from '../HoveredNoter';

const { keys, entries, } = Object;
const { abs } = Math;
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';
const countPerPage = 100;
const metrics = {
  budgetAmount: { label: '予算' },
  comparedBudgetAmount: { label: '比較予算' },
  diffBetweenBudgets: { label: '予算差額' },
  amount: { label: '当期実績' },
  budgetDiff: { label: '差額' },
  achievementRate: { label: '達成率', isRate: true, },
  estimatedAmount: { label: '着地見込' },
  prevYearAmount: { label: '前期実績' },
  prevYearDiff: { label: '増減額' },
  prevYearDiffRate: { label: '増減率', isRate: true, },
};
const metricOptions = entries(metrics).map(([k, v]) => ({ label: v.label, value: k }));
const displayColumnTypes = {
  month: { label: '月', },
  quarter: { label: '四半期', },
  half: { label: '半期', },
};
const displayColumnTypeOptions = entries(displayColumnTypes).map(([k, v]) => ({ label: v.label, value: k }));

export default CompanyPage(CompanyTwoDimensions);

function CompanyTwoDimensions(props) {
  const { company, user, role, period, targetMonth, members, sortedAccountItems: accountItems, match: { params: { companyId } } } = props;
  const { start_date: startDate, } = fiscalYearOfPeriod(period, company.fiscalYears);
  const prevYearMonths = useMemo(_ => range(-12, 0).map(_ => endOfMonth(addMonths(startDate, _))), [startDate]);
  const prevYearMonthsByMonth = useMemo(_ => keyBy(prevYearMonths, _ => formatDate(_, 'MM')), [prevYearMonths]);
  const currentYearMonths = useMemo(_ => range(0, 12).map(_ => endOfMonth(addMonths(startDate, _))), [startDate]);
  const twoDimensionsSettings = useCollectionSubscription(company.ref.collection('twoDimensionsSettings').orderBy('createdAt'), [company]);
  const accountItemsById = keyBy(accountItems, 'id');
  const computeOverMonth = (date) => {
    return date > endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _));
  };


  return (
    <div className="company-two-dimensions">
      <div className="p-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="2明細分析を行う" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>2明細分析</h3>
        </div>
        <div className="mt-5 d-flex align-items-end justify-content-end position-relative" style={{ zIndex: 4 }}>
          <AddButton itemRef={company.ref.collection('twoDimensionsSettings').doc()} FormModal={ModelFormModal} formProps={{ title: '2明細分析設定追加', fields: fields({ dimensions: dimensionsOfCompany(company), accountItems, }) }} />
        </div>
        <div className="mt-1">
          <table className="table">
            <thead className="thead-light text-center">
              <tr>
                <th style={{ minWidth: 200 }}>名称</th>
                <th style={{ minWidth: 150 }}>明細項目1</th>
                <th style={{ minWidth: 150 }}>明細項目1</th>
                <th style={{ minWidth: 250 }}>勘定科目</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                twoDimensionsSettings.map((twoDimensionsSetting) => {
                  const { id, ref, name, dimension1, dimension2, accountItemIds, } = twoDimensionsSetting;
                  const rowsForExport = async () => {
                    const twoDimensionsAmountChunks = await getAllCollectionDataByChunk(company.ref.collection('twoDimensionsAmountChunks').where('twoDimensionsSettingId', '==', id).where('yearMonth', '>=', formatDate(prevYearMonths[0], 'YYYYMM')).where('yearMonth', '<=', formatDate(last(currentYearMonths), 'YYYYMM')));
                    const twoDimensionsAmountRows = twoDimensionsAmountChunks.flatMap(_ => _.rows.map(r => ({ ..._, ...r, })));
                    const groupedTwoDimensionsAmountRows = mapValues(
                      groupBy(twoDimensionsAmountRows, 'accountItemId'),
                      _ => mapValues(
                        groupBy(_, 'dimension1Name'),
                        _ => mapValues(
                          groupBy(_, 'dimension2Name'),
                          _ => groupBy(_, 'yearMonth'),
                        )
                      )
                    );
                    const rowGroups = accountItemIds.map((accountItemId) => {
                      const accountItem = accountItemsById[accountItemId];
                      const accountItemRowGroups = entries(groupedTwoDimensionsAmountRows[accountItemId] || {}).map(([dimension1Name, dimension1Group]) => {
                        const rows = entries(dimension1Group).map(([dimension2Name, dimension2Group]) => {
                          const rowKey = [accountItemId, dimension1Name, dimension2Name].join('__');
                          const monthColumns = currentYearMonths.map((month, i) => {
                            const isOverMonth = computeOverMonth(month);
                            const prevYearMonth = prevYearMonthsByMonth[formatDate(month, 'MM')];
                            const yearMonth = formatDate(month, 'YYYYMM');
                            const amount = sumBy(dimension2Group[yearMonth], 'monthAmount');
                            const key = [rowKey, yearMonth].join('__');
                            return {
                              key,
                              month,
                              amount,
                            };
                          });
                          const dimension2Row = {
                            rowKey,
                            dimension2Name,
                            monthColumns,
                          };
                          return dimension2Row;
                        });
                        return {
                          dimension1Name,
                          rows,
                        };
                      });
                      const accountItemRows = accountItemRowGroups.flatMap(_ => _.rows.map(r => ({ ..._, ...r, })));
                      return {
                        accountItem,
                        accountItemRowGroups,
                        accountItemRows,
                      };
                    });
                    const allRows = rowGroups?.flatMap(_ => _.accountItemRows.map(r => ({ ..._, ...r, })));
                    return allRows.flatMap((row) => {
                      const { accountItem, dimension1Name, dimension2Name, monthColumns, } = row;
                      return monthColumns.map((monthColumn) => {
                        const { key, month, amount, } = monthColumn;
                        return mapKeysToJa({
                          accountItemName: accountItem?.name,
                          [dimension1]: dimension1Name,
                          [dimension2]: dimension2Name,
                          yearMonth: formatDate(month, 'YYYYMM'),
                          amount,
                        }, { amount: '金額', });
                      });
                    });
                  };

                  return (
                    <tr key={id}>
                      <td>
                        {name}
                      </td>
                      <td>
                        {dimensions[dimension1]}
                      </td>
                      <td>
                        {dimensions[dimension2]}
                      </td>
                      <td>
                        {
                          accountItemIds.map((accountItemId) => {
                            return (
                              <div key={accountItemId}>
                                {accountItemsById[accountItemId]?.name}
                              </div>
                            );
                          })
                        }
                      </td>
                      <td className="text-nowrap text-right">
                        <ExportButton outline fileName={`${name}_${targetMonth}.csv`} rows={rowsForExport} />
                        <EditButton itemRef={ref} className="ml-1" FormModal={ModelFormModal} formProps={{ documentName: 'twoDimensionsSetting', title: '2明細分析設定編集', fields: fields({ dimensions: dimensionsOfCompany(company), accountItems, }), }} />
                        <DeleteButton itemRef={ref} className="ml-1" />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function Row (props) {
  const { company, user, members, role, row, period, notesByKey, commentsGroupedByCommenteeKey, columnWidths, } = props;
  const queryParams = useQueryParams();
  const {
    metrics: metricsForFilter,
  } = queryParams;

  return (
    <Fragment>
      {
        [row].map((row, rowIndex) => {
          const { accountItem, dimension1Name, dimension2Name, monthColumns, } = row;
          return (
            <tr key={rowIndex} className={classnames('hovered-row')}>
              <th className="font-weight-normal" style={{ width: sum(columnWidths), }}>
                <div className="d-flex gap-2">
                  <div style={{ width: columnWidths[0], }}>
                    {accountItem?.name}
                  </div>
                  <div style={{ width: columnWidths[1], }}>
                    {dimension1Name}
                  </div>
                  <div style={{ width: COLUMN_WIDTH * 0.8, }}>
                    {dimension2Name}
                  </div>
                </div>
              </th>
              {
                monthColumns.map((columnItem) => {
                  const { key, month, amount, } = columnItem;
                  return (
                    <td key={key} className="text-right has-hovered-contents" style={{ width: COLUMN_WIDTH }}>
                      <span>{isFiniteNumber(amount) ? numeral(amount).format() : '-'}</span>
                    </td>
                  );
                })
              }
            </tr>
          );
        })
      }
    </Fragment>
  );
}
